// src/components/HomePage.js
import React from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import AnimatedTitle from './AnimatedTitle';
import FancyButton from './FancyButton';

const FullscreenBox = styled(Box)({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '20px',
  boxSizing: 'border-box',
  background: 'transparent',
});

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '80vh',
});

const ButtonWrapper = styled(Box)({
  marginTop: '1vh', // Adjust this value to move the button up or down
});

function HomePage() {
  const navigate = useNavigate();

  const handleLogin = () => {
    setTimeout(() => {
      navigate('/login');
    }, 10);
  };

  return (
    <FullscreenBox>
      <ContentWrapper>
        <AnimatedTitle />
        <ButtonWrapper>
          <FancyButton onClick={handleLogin}>
            Login
          </FancyButton>
        </ButtonWrapper>
      </ContentWrapper>
    </FullscreenBox>
  );
}

export default HomePage;