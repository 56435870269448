// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useNavigate, useParams, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, Button, Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { ThemeProviderWrapper, useTheme } from './contexts/ThemeContext';
import ThemedBackground from './components/ThemedBackground';
import CustomCursor from './components/CustomCursor';
import PageTransition from './components/PageTransition';
import HomePage from './components/HomePage';
import Login from './components/Login';
import UserList from './components/UserList';
import UserDetails from './components/UserDetails';
import ServerStats from './components/ServerStats';
import ChannelList from './components/ChannelList';
import MessageList from './components/MessageList';
import AuditLogList from './components/AuditLogList';  // New import for the logs
import Eula from './components/Eula';
import { GlobalStyles } from '@mui/material';


const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'linear-gradient(45deg, #0A1128 30%, #131836 90%)',
  boxShadow: '0 3px 5px 2px rgba(19, 24, 54, .3)',
}));

const GlobalCursorStyle = () => (
  <GlobalStyles
    styles={{
      '*': {
        cursor: 'none !important',
      },
      'body, a, button, [role="button"], input[type="submit"], input[type="image"], label[for], select, input[type="file"], input[type="checkbox"], input[type="radio"], .link': {
        cursor: 'none !important',
      },
      '* :hover': {
        cursor: 'none !important',
      },
    }}
  />
);

function PrivateRoute({ children }) {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!loading && !user) {
      navigate('/');
    }
  }, [user, loading, navigate]);

  if (loading) {
    return <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Typography variant="h6">Loading...</Typography>
    </Box>;
  }

  return user ? children : null;
}

function ChannelPage() {
  const { channelId } = useParams();
  
  if (!channelId) {
    return <Typography>Please select a channel</Typography>;
  }
  
  return <MessageList channelId={channelId} />;
}

function App() {
  const { user, loading, logout } = useAuth();
  const { colorMode, mode } = useTheme();
  const theme = useTheme();
  const location = useLocation();

  if (loading) {
    return <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Typography variant="h6">Loading...</Typography>
    </Box>;
  }

  return (
    <>
      <GlobalCursorStyle />
      <CustomCursor />
      <ThemedBackground />
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Pelbot
          </Typography>
          <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
            {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          {user ? (
            <>
              <Button color="inherit" component={Link} to="/users">Users</Button>
              <Button color="inherit" component={Link} to="/stats">Stats</Button>
              <Button color="inherit" component={Link} to="/logs">Logs</Button>
              <Button color="inherit" component={Link} to="/channels">Channels</Button>
              <Button color="inherit" onClick={logout}>Logout</Button>
            </>
          ) : (
            <Button color="inherit" component={Link} to="/login">Login</Button>
          )}
        </Toolbar>
      </AppBar>
      <PageTransition>
        <Container>
          <Box sx={{ paddingTop: '64px', minHeight: '100vh' }}>
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={user ? <Navigate to="/users" /> : <HomePage />} />
              <Route path="/login" element={user ? <Navigate to="/users" /> : <Login />} />
              <Route path="/users" element={<PrivateRoute><UserList /></PrivateRoute>} />
              <Route path="/users/:userId" element={<PrivateRoute><UserDetails /></PrivateRoute>} />
              <Route path="/stats" element={<PrivateRoute><ServerStats /></PrivateRoute>} />
              <Route path="/channels" element={<PrivateRoute><ChannelList /></PrivateRoute>} />
              <Route path="/logs" element={<PrivateRoute><AuditLogList /></PrivateRoute>} /> 
              <Route path="/channels/:channelId" element={<PrivateRoute><ChannelPage /></PrivateRoute>} />
              <Route path="/eula" element={<Eula />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Box>
        </Container>
      </PageTransition>
    </>
  );
}

function AppWithProviders() {
  return (
    <AuthProvider>
      <ThemeProviderWrapper>
        <Router>
          <App />
        </Router>
      </ThemeProviderWrapper>
    </AuthProvider>
  );
}

export default AppWithProviders;