// src/components/UserList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  List, ListItem, ListItemAvatar, ListItemText, Avatar, Typography, 
  CircularProgress, TextField, Pagination, Box, Paper, Select, MenuItem,
  FormControl, InputLabel, Button, Menu, Checkbox
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import api from '../utils/api';

const FullscreenBox = styled(Box)({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  boxSizing: 'border-box',
  overflowX: 'hidden',
});

const StyledPaper = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  width: '100%',
  maxWidth: '800px',
}));

const AnimatedListItem = styled(motion(ListItem))(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(5px)',
  borderRadius: '8px',
  marginBottom: '8px',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

function UserList() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState('username');
  const [sortOrder, setSortOrder] = useState('asc');
  const [roleFilter, setRoleFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState([]);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useAuth();
  const { mode } = useTheme();

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, [search, page, sortBy, sortOrder, roleFilter, statusFilter]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await api.get('/users', {
        params: {
          search,
          page,
          limit: 50,
          sortBy,
          sortOrder,
          role: roleFilter,
          status: statusFilter.join(',')
        }
      });
      setUsers(response.data.users);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await api.get('/roles');
      setAvailableRoles(response.data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
    setPage(1);
  };

  const handleSortOrderChange = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setPage(1);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleRoleFilterChange = (event) => {
    setRoleFilter(event.target.value);
    setPage(1);
  };

  const handleStatusFilterChange = (event) => {
    const value = event.target.value;
    setStatusFilter(value);
    setPage(1);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const renderSortInfo = (user) => {
    switch (sortBy) {
      case 'messageCount':
        return `Messages: ${user.messageCount}`;
      case 'joinedAt':
        return `Joined: ${new Date(user.joinedAt).toLocaleDateString()}`;
      default:
        return null;
    }
  };

  if (!user) return null;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <FullscreenBox>
      <StyledPaper 
        elevation={6}
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h4" sx={{ color: mode === 'dark' ? 'white' : 'black', mb: 3 }}>Users</Typography>
        </motion.div>
        <Box display="flex" flexDirection="column" gap={2} mb={2} width="100%">
          <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            <StyledTextField
              label="Search users"
              variant="outlined"
              fullWidth
              value={search}
              onChange={handleSearchChange}
            />
          </motion.div>
          <Box display="flex" gap={2} alignItems="center" width="100%">
            <Box display="flex" alignItems="center" flexGrow={1}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel sx={{ color: mode === 'dark' ? 'white' : 'black' }}>Sort by</InputLabel>
                <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} style={{ width: '100%' }}>
                  <StyledSelect
                    value={sortBy}
                    onChange={handleSortChange}
                    label="Sort by"
                    fullWidth
                  >
                    <MenuItem value="username">Username</MenuItem>
                    <MenuItem value="joinedAt">Join Date</MenuItem>
                    <MenuItem value="messageCount">Message Count</MenuItem>
                  </StyledSelect>
                </motion.div>
              </FormControl>
              <motion.div 
                whileHover={{ scale: 1.05 }} 
                whileTap={{ scale: 0.95 }}
                style={{ marginLeft: '8px' }}
              >
                <StyledButton
                  variant="outlined"
                  onClick={handleSortOrderChange}
                  sx={{ 
                    height: '56px',
                    minWidth: '56px',
                    padding: '0',
                  }}
                >
                  {sortOrder === 'asc' ? '↑' : '↓'}
                </StyledButton>
              </motion.div>
            </Box>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <StyledButton
                variant="outlined"
                startIcon={<FilterListIcon />}
                onClick={handleFilterClick}
                sx={{ 
                  height: '56px',
                  backgroundColor: (roleFilter || statusFilter.length > 0) ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                }}
              >
                Filter
                {(roleFilter || statusFilter.length > 0) && (
                  <Box
                    sx={{
                      width: 8,
                      height: 8,
                      borderRadius: '50%',
                      backgroundColor: '#4caf50',
                      marginLeft: 1,
                    }}
                  />
                )}
              </StyledButton>
            </motion.div>
          </Box>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleFilterClose}
          PaperProps={{
            style: {
              backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
              backdropFilter: 'blur(10px)',
            },
          }}
        >
          <MenuItem>
            <FormControl variant="outlined" fullWidth>
              <InputLabel sx={{ color: mode === 'dark' ? 'white' : 'black' }}>Role</InputLabel>
              <Select
                value={roleFilter}
                onChange={handleRoleFilterChange}
                label="Role"
                sx={{ color: mode === 'dark' ? 'white' : 'black', minWidth: '150px' }}
              >
                <MenuItem value="">
                  <em>All Roles</em>
                </MenuItem>
                {availableRoles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </MenuItem>
          <MenuItem>
          <FormControl variant="outlined" fullWidth>
              <InputLabel sx={{ color: mode === 'dark' ? 'white' : 'black' }}>Status</InputLabel>
              <Select
                multiple
                value={statusFilter}
                onChange={handleStatusFilterChange}
                label="Status"
                renderValue={(selected) => selected.join(', ')}
                sx={{ color: mode === 'dark' ? 'white' : 'black', minWidth: '150px' }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {['online', 'idle', 'dnd', 'offline'].map((status) => (
                  <MenuItem key={status} value={status}>
                    <Checkbox checked={statusFilter.indexOf(status) > -1} />
                    <ListItemText primary={status.charAt(0).toUpperCase() + status.slice(1)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MenuItem>
        </Menu>
        {loading ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <CircularProgress sx={{ color: mode === 'dark' ? 'white' : 'black' }} />
          </motion.div>
        ) : users.length === 0 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Typography sx={{ color: mode === 'dark' ? 'white' : 'black' }}>No users found.</Typography>
          </motion.div>
        ) : (
          <>
            <AnimatePresence mode="wait">
              <motion.div
                key={page + search + sortBy + sortOrder}
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                style={{ width: '100%' }}
              >
                <List>
                  {users.map(user => (
                    <AnimatedListItem
                      key={user.id}
                      component={Link}
                      to={`/users/${user.id}`}
                      variants={itemVariants}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <ListItemAvatar>
                        <Avatar src={user.avatar} alt={user.username}>
                          {user.username.charAt(0)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText 
                        primary={<Typography sx={{ color: mode === 'dark' ? 'white' : 'black' }}>{user.username}</Typography>}
                        secondary={
                          <Typography sx={{ color: mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)' }}>
                            {renderSortInfo(user)}
                          </Typography>
                        }
                      />
                    </AnimatedListItem>
                  ))}
                </List>
              </motion.div>
            </AnimatePresence>
            <Box display="flex" justifyContent="center" mt={2}>
              <Pagination 
                count={totalPages} 
                page={page} 
                onChange={handlePageChange}
                sx={{
                  '& .MuiPaginationItem-root': {
                    color: mode === 'dark' ? 'white' : 'black',
                  }
                }}
              />
            </Box>
          </>
        )}
      </StyledPaper>
    </FullscreenBox>
  );
}

export default UserList;