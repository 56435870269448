// src/contexts/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import api from '../utils/api';

const AuthContext = createContext();

const API_URL = process.env.REACT_APP_API_URL;

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkAuth();
  }, []);

  const login = async (username, password) => {
    try {
      console.log('Attempting login...');
      const response = await api.post('/login', { username, password });
      console.log('Login response:', response.data);
      const { token } = response.data;
      localStorage.setItem('token', token);
      
      // Fetch user data immediately after successful login
      await checkAuth();
      
      return true; // Indicate successful login
    } catch (error) {
      console.error('Login error:', error);
      return false; // Indicate failed login
    }
  };
  
  const checkAuth = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const response = await api.get('/me', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUser(response.data);
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error('Check auth error:', error);
      localStorage.removeItem('token');
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout, checkAuth }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}