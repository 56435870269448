// src/components/UserActivityChart.js
import React from 'react';
import { Box, Typography, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { 
  Chart as ChartJS,
  CategoryScale, 
  LinearScale, 
  BarElement, 
  PointElement, 
  LineElement, 
  Title, 
  Tooltip, 
  Legend 
} from 'chart.js';

ChartJS.register(
  CategoryScale,    
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function UserActivityChart({ activityData, activityPeriod, setActivityPeriod, mode }) {
  const lineChartData = {
    labels: activityData ? activityData.dailyActivity.map(day => day.date) : [],
    datasets: [
      {
        label: 'Messages',
        data: activityData ? activityData.dailyActivity.map(day => day.messageCount) : [],
        fill: false,
        borderColor: mode === 'dark' ? 'rgb(255, 99, 132)' : 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  return (
    <Box mt={3} sx={{ width: '100%' }}>
      <Typography variant="h5" sx={{ color: mode === 'dark' ? 'white' : 'black' }}>Activity Statistics</Typography>
      <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
        <InputLabel sx={{ color: mode === 'dark' ? 'white' : 'black' }}>Activity Period</InputLabel>
        <Select
          value={activityPeriod}
          onChange={(e) => setActivityPeriod(e.target.value)}
          label="Activity Period"
        >
          <MenuItem value={7}>Last 7 days</MenuItem>
          <MenuItem value={30}>Last 30 days</MenuItem>
          <MenuItem value={90}>Last 90 days</MenuItem>
        </Select>
      </FormControl>
      {activityData && (
        <Box sx={{ mt: 2, height: '40vh', width: '100%' }}>
          <Line 
            data={lineChartData} 
            options={{ 
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                x: {
                  grid: { color: mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)' },
                  ticks: { color: mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)' },
                },
                y: {
                  grid: { color: mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)' },
                  ticks: { color: mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)' },
                },
              },
              plugins: {
                legend: {
                  labels: { color: mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)' },
                },
              },
            }} 
          />
        </Box>
      )}
    </Box>
  );
}

export default UserActivityChart;