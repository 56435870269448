// src/components/UserAvatar.js
import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { motion } from 'framer-motion';

const LargeAvatar = ({ src, alt }) => (
  <motion.div 
    whileHover={{ scale: 1.1 }} 
    transition={{ type: "spring", stiffness: 300 }}
    style={{ marginBottom: '16px' }}
  >
    <Avatar src={src} alt={alt} sx={{ width: 100, height: 100, marginBottom: 2 }} />
  </motion.div>
);

function UserAvatar({ user, mode }) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <LargeAvatar src={user.avatar} alt={user.username} />
      <Typography variant="h4" align="center" sx={{ color: mode === 'dark' ? 'white' : 'black' }}>
        {user.username}#{user.discriminator}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" align="center" sx={{ color: mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)' }}>
        ID: {user.id}
      </Typography>
      {user.nickname && <Typography variant="subtitle2" align="center" sx={{ color: mode === 'dark' ? 'white' : 'black' }}>Nickname: {user.nickname}</Typography>}
    </Box>
  );
}

export default UserAvatar;