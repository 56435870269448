import React from 'react';
import { Box, Button, Popover } from '@mui/material';
import { styled } from '@mui/material/styles';

const ColorButton = styled(Button)(({ color, theme }) => ({
  width: '24px',
  height: '24px',
  minWidth: '24px',
  backgroundColor: color,
  '&:hover': {
    backgroundColor: color,
  },
}));

const ColorGrid = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr)',
  gap: '8px',
  padding: '8px',
});

const colors = [
  '#FF0000', '#FF7F00', '#FFFF00', '#00FF00', '#0000FF', '#8B00FF',
  '#FF69B4', '#FF1493', '#FF00FF', '#00FFFF', '#1E90FF', '#000000',
  '#FFFFFF', '#808080', '#A52A2A', '#800000', '#008000', '#000080',
];

const CustomColorPicker = ({ color, onChange }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (newColor) => {
    onChange(newColor);
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <ColorButton color={color} onClick={handleClick} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ColorGrid>
          {colors.map((c) => (
            <ColorButton key={c} color={c} onClick={() => handleColorChange(c)} />
          ))}
        </ColorGrid>
      </Popover>
    </>
  );
};

export default CustomColorPicker;