// src/components/AnimatedTitle.js
import React from 'react';
import { motion } from 'framer-motion';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const TitleWrapper = styled(Box)({
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '40vh', // Reduced height
  marginTop: '-10vh', // Move title up
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "'Montserrat', sans-serif",
  fontWeight: 900,
  fontSize: 'clamp(4rem, 15vw, 12rem)', // Slightly reduced font size
  textAlign: 'center',
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textShadow: '4px 4px 8px rgba(0,0,0,0.2)',
}));

const letterVariants = {
  hidden: { y: 100, opacity: 0 },
  visible: { y: 0, opacity: 1 },
};

const AnimatedTitle = () => {
  const title = "PELBOT";

  return (
    <TitleWrapper>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          visible: {
            transition: {
              staggerChildren: 0.1,
            },
          },
        }}
      >
        {title.split('').map((char, index) => (
          <motion.span
            key={`${char}-${index}`}
            variants={letterVariants}
            transition={{ 
              type: 'spring', 
              stiffness: 100,
              damping: 10,
            }}
            style={{ display: 'inline-block' }}
            whileHover={{ 
              scale: 1.2, 
              rotate: Math.random() * 20 - 10,
              transition: { duration: 0.3 }
            }}
          >
            <StyledTypography component="span">{char}</StyledTypography>
          </motion.span>
        ))}
      </motion.div>
    </TitleWrapper>
  );
};

export default AnimatedTitle;