import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  Grid,
  Divider,
  Switch,
  FormControlLabel,
  Tooltip,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import CustomColorPicker from './CustomColorPicker';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    background: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiInputBase-input': {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
  },
}));

const ColorButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  border: `1px solid ${theme.palette.mode === 'dark' ? 'white' : 'black'}`,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const EmbedPreview = styled(Paper)(({ theme, color }) => ({
  border: '1px solid rgba(255, 255, 255, 0.2)',
  borderRadius: '4px',
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  background: '#36393f',
  color: '#dcddde',
  maxWidth: '520px',
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: '4px',
    backgroundColor: color,
  },
}));


const EmbedTitle = styled(Typography)(({ theme }) => ({
  color: '#ffffff',
  fontWeight: 'bold',
  fontSize: '16px',
  marginBottom: theme.spacing(1),
}));

const EmbedDescription = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  marginBottom: theme.spacing(2),
}));

const EmbedAuthor = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const EmbedAuthorIcon = styled('img')({
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  marginRight: '8px',
});

const EmbedAuthorName = styled(Typography)({
  fontSize: '14px',
  fontWeight: 'bold',
});

const EmbedField = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const EmbedFieldName = styled(Typography)({
  fontSize: '14px',
  fontWeight: 'bold',
  marginBottom: '4px',
});

const EmbedFieldValue = styled(Typography)({
  fontSize: '14px',
});

const EmbedFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  fontSize: '12px',
  color: '#72767d',
}));

const EmbedFooterIcon = styled('img')({
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  marginRight: '8px',
});

const EmbedImage = styled('img')({
  maxWidth: '100%',
  maxHeight: '300px',
  borderRadius: '4px',
  marginTop: '8px',
});

const EmbedThumbnail = styled('img')({
  width: '80px',
  height: '80px',
  borderRadius: '4px',
  float: 'right',
  marginLeft: '16px',
});

const EmbedEditor = ({ open, onClose, onSave }) => {
  const [embed, setEmbed] = useState({
    title: '',
    description: '',
    color: '#000000',
    author: { name: '', icon_url: '', url: '' },
    footer: { text: '', icon_url: '' },
    image: { url: '' },
    thumbnail: { url: '' },
    fields: [],
    timestamp: null,
  });

  const handleChange = (field, subfield = null) => (event) => {
    if (subfield) {
      setEmbed({ ...embed, [field]: { ...embed[field], [subfield]: event.target.value } });
    } else {
      setEmbed({ ...embed, [field]: event.target.value });
    }
  };

  const handleAddField = () => {
    setEmbed({ ...embed, fields: [...embed.fields, { name: '', value: '', inline: false }] });
  };

  const handleFieldChange = (index, key) => (event) => {
    const newFields = [...embed.fields];
    newFields[index][key] = event.target.value;
    setEmbed({ ...embed, fields: newFields });
  };

  const handleFieldInlineToggle = (index) => () => {
    const newFields = [...embed.fields];
    newFields[index].inline = !newFields[index].inline;
    setEmbed({ ...embed, fields: newFields });
  };

  const handleRemoveField = (index) => () => {
    const newFields = embed.fields.filter((_, i) => i !== index);
    setEmbed({ ...embed, fields: newFields });
  };

  const handleTimestampToggle = () => {
    setEmbed({ ...embed, timestamp: embed.timestamp ? null : new Date().toISOString() });
  };

  const handleSave = () => {
    onSave(embed);
    onClose();
  };
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newFields = Array.from(embed.fields);
    const [reorderedField] = newFields.splice(result.source.index, 1);
    newFields.splice(result.destination.index, 0, reorderedField);
    setEmbed({ ...embed, fields: newFields });
  };

  const renderPreview = () => (
    <EmbedPreview>
      {embed.author.name && (
        <EmbedAuthor>
          {embed.author.icon_url && <EmbedAuthorIcon src={embed.author.icon_url} alt="Author Icon" />}
          <EmbedAuthorName>{embed.author.name}</EmbedAuthorName>
        </EmbedAuthor>
      )}
      {embed.title && <EmbedTitle style={{ color: embed.color }}>{embed.title}</EmbedTitle>}
      {embed.description && <EmbedDescription>{embed.description}</EmbedDescription>}
      {embed.thumbnail.url && <EmbedThumbnail src={embed.thumbnail.url} alt="Thumbnail" />}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="fields">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {embed.fields.map((field, index) => (
                <Draggable key={index} draggableId={`field-${index}`} index={index}>
                  {(provided) => (
                    <EmbedField
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{ display: 'inline-block', width: field.inline ? '50%' : '100%', ...provided.draggableProps.style }}
                    >
                      <EmbedFieldName>{field.name}</EmbedFieldName>
                      <EmbedFieldValue>{field.value}</EmbedFieldValue>
                    </EmbedField>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {embed.image.url && <EmbedImage src={embed.image.url} alt="Embed Image" />}
      {(embed.footer.text || embed.timestamp) && (
        <EmbedFooter>
          {embed.footer.icon_url && <EmbedFooterIcon src={embed.footer.icon_url} alt="Footer Icon" />}
          <span>
            {embed.footer.text}
            {embed.footer.text && embed.timestamp && ' • '}
            {embed.timestamp && new Date(embed.timestamp).toLocaleString()}
          </span>
        </EmbedFooter>
      )}
    </EmbedPreview>
  );

  return (
    <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Embed Editor</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>Embed Content</Typography>
                <Tooltip title="The main title of the embed">
                  <StyledTextField
                    fullWidth
                    label="Title"
                    value={embed.title}
                    onChange={handleChange('title')}
                  />
                </Tooltip>
                <Tooltip title="The main content of the embed">
                  <StyledTextField
                    fullWidth
                    label="Description"
                    multiline
                    rows={4}
                    value={embed.description}
                    onChange={handleChange('description')}
                  />
                </Tooltip>
                <Box display="flex" alignItems="center" mb={2}>
                  <Typography variant="body2" mr={1}>
                    Color:
                  </Typography>
                  <CustomColorPicker
                    color={embed.color}
                    onChange={(newColor) => setEmbed({ ...embed, color: newColor })}
                  />
                </Box>
                <Typography variant="h6" gutterBottom>Author</Typography>
                <Tooltip title="The name of the author">
                  <StyledTextField
                    fullWidth
                    label="Author Name"
                    value={embed.author.name}
                    onChange={handleChange('author', 'name')}
                  />
                </Tooltip>
                <Tooltip title="The URL of the author's icon">
                  <StyledTextField
                    fullWidth
                    label="Author Icon URL"
                    value={embed.author.icon_url}
                    onChange={handleChange('author', 'icon_url')}
                  />
                </Tooltip>
                <Tooltip title="A URL that opens when the author's name is clicked">
                  <StyledTextField
                    fullWidth
                    label="Author URL"
                    value={embed.author.url}
                    onChange={handleChange('author', 'url')}
                  />
                </Tooltip>
                <Typography variant="h6" gutterBottom>Footer</Typography>
                <Tooltip title="Text that appears in the footer of the embed">
                  <StyledTextField
                    fullWidth
                    label="Footer Text"
                    value={embed.footer.text}
                    onChange={handleChange('footer', 'text')}
                  />
                </Tooltip>
                <Tooltip title="The URL of an icon to display in the footer">
                  <StyledTextField
                    fullWidth
                    label="Footer Icon URL"
                    value={embed.footer.icon_url}
                    onChange={handleChange('footer', 'icon_url')}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>Images</Typography>
                <Tooltip title="The URL of the main image in the embed">
                  <StyledTextField
                    fullWidth
                    label="Image URL"
                    value={embed.image.url}
                    onChange={handleChange('image', 'url')}
                  />
                </Tooltip>
                <Tooltip title="The URL of a small image to display in the upper right corner">
                  <StyledTextField
                    fullWidth
                    label="Thumbnail URL"
                    value={embed.thumbnail.url}
                    onChange={handleChange('thumbnail', 'url')}
                  />
                </Tooltip>
                <Typography variant="h6" gutterBottom>Fields</Typography>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="fields">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {embed.fields.map((field, index) => (
                          <Draggable key={index} draggableId={`field-${index}`} index={index}>
                            {(provided) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                mb={2}
                              >
                                <Box display="flex" alignItems="center">
                                  <DragIndicatorIcon />
                                  <Typography variant="subtitle1" ml={1}>Field {index + 1}</Typography>
                                </Box>
                                <StyledTextField
                                  fullWidth
                                  label={`Field ${index + 1} Name`}
                                  value={field.name}
                                  onChange={handleFieldChange(index, 'name')}
                                />
                                <StyledTextField
                                  fullWidth
                                  label={`Field ${index + 1} Value`}
                                  value={field.value}
                                  onChange={handleFieldChange(index, 'value')}
                                />
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={field.inline}
                                      onChange={handleFieldInlineToggle(index)}
                                      color="primary"
                                    />
                                  }
                                  label="Inline"
                                />
                                <IconButton onClick={handleRemoveField(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <Button startIcon={<AddIcon />} onClick={handleAddField}>
                  Add Field
                </Button>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!embed.timestamp}
                      onChange={handleTimestampToggle}
                      color="primary"
                    />
                  }
                  label="Include Timestamp"
                />
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>Embed Preview</Typography>
            <EmbedPreview color={embed.color}>
            {renderPreview()}
            </EmbedPreview>
          </motion.div>
        </AnimatePresence>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default EmbedEditor