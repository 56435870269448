// src/components/PageTransition.js
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const PageTransition = ({ children }) => {
  const location = useLocation();
  const theme = useTheme();

  const gradientColors = theme.palette.mode === 'dark'
    ? ['#1A2138', '#221D35']  // Dark mode colors
    : ['#FF8E53', '#FE6B8B'];  // Light mode colors

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      <AnimatePresence mode="wait">
        <motion.div
          key={location.pathname}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
      <motion.div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `linear-gradient(45deg, ${gradientColors[0]}, ${gradientColors[1]})`,
          zIndex: 999,
          pointerEvents: 'none',
          boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,  // Outline
        }}
        initial={{ scaleY: 0, originY: '100%' }}
        animate={{ 
          scaleY: [0, 1, 1, 0], 
          originY: ['100%', '100%', '0%', '0%'],
        }}
        transition={{ 
          duration: 1, 
          times: [0, 0.4, 0.6, 1], 
          ease: "easeInOut" 
        }}
        key={location.pathname}
      />
    </div>
  );
};

export default PageTransition;