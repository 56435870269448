// src/components/ThemedBackground.js
import React from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { useTheme } from '../contexts/ThemeContext';
import { motion } from 'framer-motion';

const subtleAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const twinkle = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

const BackgroundContainer = styled(motion.div)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  backgroundSize: '400% 400%',
}));

const Star = styled('div')(({ size, top, left, delay }) => ({
  position: 'absolute',
  width: size,
  height: size,
  backgroundColor: '#fff',
  borderRadius: '50%',
  top,
  left,
  animation: `${twinkle} 2s infinite`,
  animationDelay: delay,
}));

const FloatingParticle = styled(motion.div)({
  width: '5px',
  height: '5px',
  borderRadius: '50%',
  background: 'rgba(255, 255, 255, 0.5)',
  position: 'absolute',
});

const ThemedBackground = () => {
  const { mode } = useTheme();

  return (
    <BackgroundContainer
      animate={{
        background: mode === 'dark'
          ? [
              'linear-gradient(-45deg, #0A1128, #1A2138, #221D35, #0A1128)',
              'linear-gradient(-45deg, #1A2138, #221D35, #0A1128, #1A2138)',
              'linear-gradient(-45deg, #0A1128, #1A2138, #221D35, #0A1128)',
            ]
          : [
              'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
              'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
              'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            ],
      }}
      transition={{
        duration: 10,
        repeat: Infinity,
        repeatType: 'reverse',
      }}
    >
      {mode === 'dark' ? (
        <>
          {[...Array(50)].map((_, i) => (
            <Star
              key={i}
              size={`${Math.random() * 2 + 1}px`}
              top={`${Math.random() * 100}%`}
              left={`${Math.random() * 100}%`}
              delay={`${Math.random() * 2}s`}
            />
          ))}
        </>
      ) : (
        <>
          {[...Array(20)].map((_, i) => (
            <FloatingParticle
              key={i}
              initial={{ y: '100vh', x: `${Math.random() * 100}vw` }}
              animate={{ y: -10, x: `${Math.random() * 100}vw` }}
              transition={{
                duration: 10 + Math.random() * 10,
                repeat: Infinity,
                delay: i * 0.5,
                ease: 'linear',
              }}
            />
          ))}
        </>
      )}
    </BackgroundContainer>
  );
};

export default ThemedBackground;