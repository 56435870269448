// src/components/AuditLogList.js
import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { 
  List, ListItem, ListItemText, Typography, 
  CircularProgress, TextField, Pagination, Box, Paper, Select, MenuItem,
  FormControl, InputLabel, Button, Menu, Chip, Tooltip, IconButton,
  Collapse, Dialog, DialogTitle, DialogContent, DialogActions,
  Autocomplete
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InfoIcon from '@mui/icons-material/Info';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import api from '../utils/api';
import { DateRangePicker } from '@mui/lab';

const FullscreenBox = styled(Box)({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  boxSizing: 'border-box',
  overflowX: 'hidden',
});

const StyledPaper = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  width: '100%',
  maxWidth: '800px',
}));

const AnimatedListItem = styled(motion(ListItem))(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(5px)',
  borderRadius: '8px',
  marginBottom: '8px',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const actionTypes = [
  'Message Delete', 'Message Update', 'Guild Member Update', 'Channel Create', 'Channel Delete', 'Channel Update',
  'Role Create', 'Role Delete', 'Role Update', 'Ban Add', 'Ban Remove', 'Guild Update',
  'Emoji Create', 'Emoji Update', 'Emoji Delete', 'Sticker Create', 'Sticker Update', 'Sticker Delete',
  'Invite Create', 'Invite Delete', 'Webhook Create', 'Webhook Update', 'Webhook Delete',
  'Thread Create', 'Thread Update', 'Thread Delete', 'User Update', 'Voice State Update', 'Warning Issued',
  'Remove Warning',
  'Channel Lockdown',
  'Channel Unlock',
  'User Muted',
  'User Unmuted',
  'User Kicked',
  'User Banned',
  'User Unbanned',
];

const actionColors = {
  'Message Delete': '#FF6B6B',
  'Message Update': '#4ECDC4',
  'Guild Member Update': '#45B7D1',
  'Channel Create': '#98D8C8',
  'Channel Delete': '#FF8A5B',
  'Channel Update': '#FECA57',
  'Role Create': '#FF9FF3',
  'Role Delete': '#F368E0',
  'Role Update': '#FF9FF3',
  'Ban Add': '#EE5253',
  'Ban Remove': '#5CCB5F',
  'Guild Update': '#54A0FF',
  'Warning Issued': '#FFA500',
  'Remove Warning': '#32CD32',
  'Channel Lockdown': '#FF4500',
  'Channel Unlock': '#1E90FF',
  'User Muted': '#8A2BE2',
  'User Unmuted': '#20B2AA',
  'User Kicked': '#FF6347',
  'User Banned': '#DC143C',
  'User Unbanned': '#3CB371',
  // ... add colors for other action types
};



function AuditLogList() {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState('timestamp');
  const [sortOrder, setSortOrder] = useState('desc');
  const [actionTypeFilter, setActionTypeFilter] = useState('');
  const [userFilter, setUserFilter] = useState('');
  const [channelFilter, setChannelFilter] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandedLogId, setExpandedLogId] = useState(null);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [users, setUsers] = useState([]);
  const [channels, setChannels] = useState([]);
  const { user } = useAuth();
  const { mode } = useTheme();

  useEffect(() => {
    fetchLogs();
    fetchUsers();
    fetchChannels();
  }, [search, page, sortBy, sortOrder, actionTypeFilter, userFilter, channelFilter, dateRange]);

  const fetchLogs = async () => {
    setLoading(true);
    try {
      const response = await api.get('/audit-logs', {
        params: {
          search,
          page,
          limit: 50,
          sortBy,
          sortOrder,
          actionType: actionTypeFilter,
          userId: userFilter,
          channelId: channelFilter,
          startDate: dateRange[0]?.toISOString(),
          endDate: dateRange[1]?.toISOString()
        }
      });
      setLogs(response.data.logs);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching audit logs:', error);
      setError('Failed to fetch audit logs. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await api.get('/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchChannels = async () => {
    try {
      const response = await api.get('/channels');
      setChannels(response.data);
    } catch (error) {
      console.error('Error fetching channels:', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
    setPage(1);
  };

  const handleSortOrderChange = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setPage(1);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleActionTypeFilterChange = (event) => {
    setActionTypeFilter(event.target.value);
    setPage(1);
  };

  const handleUserFilterChange = (event, newValue) => {
    setUserFilter(newValue ? newValue.id : '');
    setPage(1);
  };

  const handleChannelFilterChange = (event, newValue) => {
    setChannelFilter(newValue ? newValue.id : '');
    setPage(1);
  };

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
    setPage(1);
  };

  const handleExpandLog = (logId) => {
    setExpandedLogId(expandedLogId === logId ? null : logId);
  };

  const handleOpenDetailDialog = (log) => {
    setSelectedLog(log);
    setDetailDialogOpen(true);
  };

  const handleCloseDetailDialog = () => {
    setDetailDialogOpen(false);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const renderLogContent = (log) => {
    let details;
    try {
      details = JSON.parse(log.reason);
    } catch (e) {
      details = { error: 'Failed to parse log details' };
    }
  
    const renderUserLink = (userId, displayName) => (
      <Link to={`/users/${userId}`} style={{ color: 'inherit', textDecoration: 'underline' }}>
        {displayName || userId}
      </Link>
    );
  
    const renderChannelLink = (channelId, displayName) => (
      <Link to={`/channels/${channelId}`} style={{ color: 'inherit', textDecoration: 'underline' }}>
        {displayName || `#${channelId}`}
      </Link>
    );
  
    switch (log.action_type) {
      case 'Message Delete':
        return (
          <>
            <Typography>Content: {details.content || 'No content'}</Typography>
            <Typography>Author: {renderUserLink(details.author?.id, details.author?.tag)}</Typography>
            <Typography>Channel: {renderChannelLink(log.channel_id, details.channelName)}</Typography>
          </>
        );
      
        case 'Warning Issued':
      return (
        <>
          <Typography>User: {renderUserLink(log.target_id, log.user_name)}</Typography>
          <Typography>Warning ID: {log.id || 'N/A'}</Typography>
          <Typography>Reason: {typeof details === 'string' ? details : (details.reason || 'No reason provided')}</Typography>
        </>
      );
    case 'Remove Warning':
      return (
        <>
          <Typography>User: {renderUserLink(log.target_id, log.user_name)}</Typography>
          <Typography>Removed Warning ID: {typeof details === 'object' && details.warningId ? details.warningId : 'N/A'}</Typography>
          <Typography>Original Reason: {typeof details === 'object' && details.originalReason ? details.originalReason : 'No reason provided'}</Typography>
        </>
      );
    case 'Channel Lockdown':
      return (
        <>
          <Typography>Channel: {renderChannelLink(log.channel_id, log.channel_name)}</Typography>
          <Typography>Duration: {typeof details === 'string' ? 'Unknown' : details.duration}</Typography>
          <Typography>Reason: {typeof details === 'string' ? details : details.reason || 'No reason provided'}</Typography>
        </>
      );
    case 'Channel Unlock':
      return (
        <>
          <Typography>Channel: {renderChannelLink(log.channel_id, log.channel_name)}</Typography>
          <Typography>Unlocked at: {new Date(log.timestamp).toLocaleString()}</Typography>
        </>
      );
        case 'User Muted':
          return (
            <>
              <Typography>User: {renderUserLink(log.target_id, details.userName)}</Typography>
              <Typography>Duration: {details.duration}</Typography>
              <Typography>Reason: {details.reason}</Typography>
            </>
          );
        case 'User Unmuted':
          return (
            <>
              <Typography>User: {renderUserLink(log.target_id, details.userName)}</Typography>
            </>
          );
        case 'User Kicked':
          return (
            <>
              <Typography>User: {renderUserLink(log.target_id, details.userName)}</Typography>
              <Typography>Reason: {details.reason}</Typography>
            </>
          );
        case 'User Banned':
          return (
            <>
              <Typography>User: {renderUserLink(log.target_id, details.userName)}</Typography>
              <Typography>Reason: {details.reason}</Typography>
              <Typography>Duration: {details.duration || 'Permanent'}</Typography>
            </>
          );
        case 'User Unbanned':
          return (
            <>
              <Typography>User: {renderUserLink(log.target_id, details.userName)}</Typography>
            </>
          );
      case 'Message Update':
        return (
          <>
            <Typography>Before: {details.oldContent || 'No content'}</Typography>
            <Typography>After: {details.newContent || 'No content'}</Typography>
            <Typography>Author: {renderUserLink(details.author?.id, details.author?.tag)}</Typography>
            <Typography>Channel: {renderChannelLink(log.channel_id, details.channelName)}</Typography>
          </>
        );
        
      case 'Guild Member Update':
        return (
          <>
            <Typography>User: {renderUserLink(details.user?.id, details.user?.tag)}</Typography>
            {Object.entries(details.changes).map(([key, value]) => (
              <Typography key={key}>{key}: {value.old} → {value.new}</Typography>
            ))}
          </>
        );
      case 'Channel Create':
      case 'Channel Delete':
      case 'Channel Update':
        return (
          <>
            <Typography>Channel: {renderChannelLink(details.id, details.name)}</Typography>
            <Typography>Type: {details.type}</Typography>
            {details.changes && Object.entries(details.changes).map(([key, value]) => (
              <Typography key={key}>{key}: {value.old} → {value.new}</Typography>
            ))}
          </>
        );
      case 'Role Create':
      case 'Role Delete':
      case 'Role Update':
        return (
          <>
            <Typography>Name: {details.name}</Typography>
            <Typography>Color: {details.color}</Typography>
            {details.changes && Object.entries(details.changes).map(([key, value]) => (
              <Typography key={key}>{key}: {value.old} → {value.new}</Typography>
            ))}
          </>
        );
      case 'Ban Add':
      case 'Ban Remove':
        return (
          <>
            <Typography>User: {renderUserLink(details.user?.id, details.user?.tag)}</Typography>
            {details.reason && <Typography>Reason: {details.reason}</Typography>}
          </>
        );
      case 'Guild Update':
        return (
          <>
            {Object.entries(details.changes).map(([key, value]) => (
              <Typography key={key}>{key}: {value.old} → {value.new}</Typography>
            ))}
          </>
        );
      // Add cases for other action types here
      default:
        return <Typography>{JSON.stringify(details, null, 2)}</Typography>;
    }
  };

  const renderDetailedLogContent = (log) => {
    // Similar to renderLogContent, but with more detailed information
    // This can include all available information about the log entry
  };


  const filteredLogs = useMemo(() => {
    return logs.filter(log => {
      const matchesSearch = log.action_type.toLowerCase().includes(search.toLowerCase()) ||
                            log.user_id.toLowerCase().includes(search.toLowerCase()) ||
                            log.channel_id.toLowerCase().includes(search.toLowerCase());
      const matchesActionType = !actionTypeFilter || log.action_type === actionTypeFilter;
      const matchesUser = !userFilter || log.user_id === userFilter;
      const matchesChannel = !channelFilter || log.channel_id === channelFilter;
      const matchesDateRange = (!dateRange[0] || new Date(log.timestamp) >= dateRange[0]) &&
                               (!dateRange[1] || new Date(log.timestamp) <= dateRange[1]);
      
      return matchesSearch && matchesActionType && matchesUser && matchesChannel && matchesDateRange;
    });
  }, [logs, search, actionTypeFilter, userFilter, channelFilter, dateRange]);

  if (!user) return null;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <FullscreenBox>
      <StyledPaper 
        elevation={6}
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h4" sx={{ color: mode === 'dark' ? 'white' : 'black', mb: 3 }}>Audit Logs</Typography>
        </motion.div>
        <Box display="flex" flexDirection="column" gap={2} mb={2} width="100%">
          <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            <StyledTextField
              label="Search logs"
              variant="outlined"
              fullWidth
              value={search}
              onChange={handleSearchChange}
            />
          </motion.div>
          <Box display="flex" gap={2} alignItems="center" width="100%">
            <Box display="flex" alignItems="center" flexGrow={1}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel sx={{ color: mode === 'dark' ? 'white' : 'black' }}>Sort by</InputLabel>
                <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} style={{ width: '100%' }}>
                  <StyledSelect
                    value={sortBy}
                    onChange={handleSortChange}
                    label="Sort by"
                    fullWidth
                  >
                    <MenuItem value="timestamp">Timestamp</MenuItem>
                    <MenuItem value="action_type">Action Type</MenuItem>
                    <MenuItem value="user_id">User</MenuItem>
                  </StyledSelect>
                </motion.div>
              </FormControl>
              <motion.div 
                whileHover={{ scale: 1.05 }} 
                whileTap={{ scale: 0.95 }}
                style={{ marginLeft: '8px' }}
              >
                <StyledButton
                  variant="outlined"
                  onClick={handleSortOrderChange}
                  sx={{ 
                    height: '56px',
                    minWidth: '56px',
                    padding: '0',
                  }}
                >
                  {sortOrder === 'asc' ? '↑' : '↓'}
                </StyledButton>
              </motion.div>
            </Box>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <StyledButton
                variant="outlined"
                startIcon={<FilterListIcon />}
                onClick={handleFilterClick}
                sx={{ 
                  height: '56px',
                  backgroundColor: (actionTypeFilter || userFilter || channelFilter || dateRange[0] || dateRange[1]) ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                }}
              >
                Filter
                {(actionTypeFilter || userFilter || channelFilter || dateRange[0] || dateRange[1]) && (
                  <Box
                    sx={{
                      width: 8,
                      height: 8,
                      borderRadius: '50%',
                      backgroundColor: '#4caf50',
                      marginLeft: 1,
                    }}
                  />
                )}
              </StyledButton>
            </motion.div>
          </Box>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleFilterClose}
          PaperProps={{
            style: {
              backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
              backdropFilter: 'blur(10px)',
            },
          }}
        >
          <MenuItem>
            <FormControl variant="outlined" fullWidth>
              <InputLabel sx={{ color: mode === 'dark' ? 'white' : 'black' }}>Action Type</InputLabel>
              <Select
                value={actionTypeFilter}
                onChange={handleActionTypeFilterChange}
                label="Action Type"
                sx={{ color: mode === 'dark' ? 'white' : 'black', minWidth: '150px' }}
              >
                <MenuItem value="">
                  <em>All Actions</em>
                </MenuItem>
                {actionTypes.map(type => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </MenuItem>
          <MenuItem>
            <Autocomplete
              options={users}
              getOptionLabel={(option) => option.username}
              renderInput={(params) => <TextField {...params} label="User" />}
              onChange={handleUserFilterChange}
              sx={{ minWidth: '150px' }}
            />
          </MenuItem>
          <MenuItem>
            <Autocomplete
              options={channels}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Channel" />}
              onChange={handleChannelFilterChange}
              sx={{ minWidth: '150px' }}
            />
          </MenuItem>
          <MenuItem>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={dateRange}
              onChange={handleDateRangeChange}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </>
              )}
            />
          </MenuItem>
        </Menu>
        {loading ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <CircularProgress sx={{ color: mode === 'dark' ? 'white' : 'black' }} />
          </motion.div>
        ) : filteredLogs.length === 0 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Typography sx={{ color: mode === 'dark' ? 'white' : 'black' }}>No logs found.</Typography>
          </motion.div>
        ) : (
          <>
            <AnimatePresence mode="wait">
              <motion.div
                key={page + search + sortBy + sortOrder}
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                style={{ width: '100%' }}
              >
                <List>
                  {filteredLogs.map(log => (
                    <AnimatedListItem
                      key={log.id}
                      variants={itemVariants}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      sx={{
                        borderLeft: `4px solid ${actionColors[log.action_type] || '#888'}`,
                      }}
                    >
                      <ListItemText 
                        primary={
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography sx={{ color: mode === 'dark' ? 'white' : 'black' }}>
                              {log.action_type}
                            </Typography>
                            <Box>
                              <Tooltip title={new Date(log.timestamp).toLocaleString()}>
                                <Chip 
                                  label={new Date(log.timestamp).toLocaleDateString()} 
                                  size="small"
                                  sx={{ backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)', mr: 1 }}
                                />
                              </Tooltip>
                              <IconButton size="small" onClick={() => handleExpandLog(log.id)}>
                                {expandedLogId === log.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                              </IconButton>
                              <IconButton size="small" onClick={() => handleOpenDetailDialog(log)}>
                                <InfoIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        }
                        secondary={
                          <Collapse in={expandedLogId === log.id}>
                            <Box sx={{ color: mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)' }}>
                              {renderLogContent(log)}
                            </Box>
                          </Collapse>
                        }
                      />
                    </AnimatedListItem>
                  ))}
                </List>
              </motion.div>
            </AnimatePresence>
            <Box display="flex" justifyContent="center" mt={2}>
              <Pagination 
                count={totalPages} 
                page={page} 
                onChange={handlePageChange}
                sx={{
                  '& .MuiPaginationItem-root': {
                    color: mode === 'dark' ? 'white' : 'black',
                  }
                }}
              />
            </Box>
          </>
        )}
      </StyledPaper>
      <Dialog open={detailDialogOpen} onClose={handleCloseDetailDialog}>
        <DialogTitle>{selectedLog?.action_type}</DialogTitle>
        <DialogContent>
          {selectedLog && renderDetailedLogContent(selectedLog)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetailDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </FullscreenBox>
  );
}

export default AuditLogList;