// src/components/UserRoles.js
import React from 'react';
import { Chip, Typography, Box } from '@mui/material';

function UserRoles({ roles, mode }) {
  return (
    <Box mt={2}>
      <Typography variant="h6" sx={{ color: mode === 'dark' ? 'white' : 'black' }}>Roles:</Typography>
      {roles.map((role) => (
        <Chip 
          key={role.id} 
          label={role.name} 
          style={{ backgroundColor: `#${role.color.toString(16)}`, margin: 4 }}
        />
      ))}
    </Box>
  );
}

export default UserRoles;