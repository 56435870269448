// src/components/CustomCursor.js
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

const CursorWrapper = styled(motion.div)`
  pointer-events: none;
  position: fixed;
  z-index: 9999;
  transform: translate(-50%, -50%);
`;

const Cursor = styled(motion.div)`
  border-radius: 50%;
`;

const Sparkle = styled(motion.div)`
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
`;

const CustomCursor = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [cursorVariant, setCursorVariant] = useState('default');
  const [isClicked, setIsClicked] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const updateMousePosition = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    const handleMouseDown = () => {
      setIsClicked(true);
      setCursorVariant('clicked');
      setTimeout(() => setCursorVariant('default'), 150);
    };
    const handleMouseUp = () => setIsClicked(false);

    const handleMouseEnter = (e) => {
      const interactiveElements = ['A', 'BUTTON', 'INPUT', 'SELECT', 'TEXTAREA'];
      if (interactiveElements.includes(e.target.tagName) || e.target.getAttribute('role') === 'button') {
        setCursorVariant('hover');
      }
    };

    const handleMouseLeave = () => setCursorVariant('default');

    window.addEventListener('mousemove', updateMousePosition);
    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mouseover', handleMouseEnter);
    document.addEventListener('mouseout', handleMouseLeave);

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mouseover', handleMouseEnter);
      document.removeEventListener('mouseout', handleMouseLeave);
    };
  }, []);

  const variants = {
    default: { width: 10, height: 10 },
    hover: { width: 20, height: 20 },
    clicked: { width: 25, height: 25, transition: { duration: 0.1 } },
  };

  const cursorColor = theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000';

  return (
    <CursorWrapper
      style={{
        left: mousePosition.x,
        top: mousePosition.y,
      }}
    >
      <Cursor
        variants={variants}
        animate={cursorVariant}
        transition={{
          type: 'spring',
          stiffness: 300,
          damping: 20,
          mass: 0.5,
        }}
        style={{
          backgroundColor: cursorColor,
        }}
      />
      <AnimatePresence>
        {isClicked && (
          [...Array(5)].map((_, i) => (
            <Sparkle
              key={i}
              initial={{ opacity: 0, scale: 0 }}
              animate={{
                x: Math.random() * 40 - 20,
                y: Math.random() * 40 - 20,
                scale: [0, 1, 0],
                opacity: [1, 0],
              }}
              exit={{ opacity: 0, scale: 0 }}
              transition={{ duration: 0.5 }}
              style={{
                backgroundColor: cursorColor,
              }}
            />
          ))
        )}
      </AnimatePresence>
    </CursorWrapper>
  );
};

export default CustomCursor;