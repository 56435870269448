// src/components/ServerStats.js
import React, { useState, useEffect } from 'react';
import { Typography, Paper, CircularProgress, Grid } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import api from '../utils/api';

function ServerStats() {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      fetchServerStats();
    }
  }, [user]);

  const fetchServerStats = async () => {
    try {
      const response = await api.get('/server-stats');
      setStats(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching server stats:', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
        setError(`Server error: ${error.response.status}. ${error.response.data.error || ''}`);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Error request:', error.request);
        setError('No response received from server. Please check your connection.');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
        setError(`Error: ${error.message}`);
      }
      setLoading(false);
    }
  };

  if (!user) return <Typography>Please log in to view server statistics.</Typography>;
  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!stats) return null;

  return (
    <Paper sx={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>Server Statistics</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <Typography variant="h6">Total Members</Typography>
          <Typography>{stats.totalMembers}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="h6">Online Members</Typography>
          <Typography>{stats.onlineMembers}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="h6">Roles</Typography>
          <Typography>{stats.roleCount}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography variant="h6">Channels</Typography>
          <Typography>{stats.channelCount}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ServerStats;