// src/components/WarningsList.js
import React from 'react';
import { List, Typography, IconButton, ListItemText } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles'; 

const WarningItem = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(5px)',
  borderRadius: '8px',
  marginBottom: '8px',
  padding: '16px',
  position: 'relative',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  },
}));

function WarningsList({ warnings, onRemoveWarning, mode }) {
  return (
    <List sx={{ width: '100%' }}>
      <AnimatePresence>
        {warnings.map((warning) => (
          <WarningItem
            key={warning.id}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.3 }}
          >
            <ListItemText
              primary={
                <Typography sx={{ color: mode === 'dark' ? '#FFD700' : 'rgba(211, 47, 47)', fontWeight: 'bold' }}>
                  Warning {warning.id}
                </Typography>
              }
              secondary={
                <>
                  <Typography component="span" variant="body2" sx={{ color: mode === 'dark' ? 'rgba(255, 255, 255, 0.9)' : 'rgba(0, 0, 0, 0.9)', display: 'block' }}>
                    Reason: {warning.reason}
                  </Typography>
                  <Typography component="span" variant="body2" sx={{ color: mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)', display: 'block' }}>
                    Moderator: {warning.moderatorNickname}
                  </Typography>
                  <Typography component="span" variant="body2" sx={{ color: mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)', display: 'block' }}>
                    Date: {new Date(warning.timestamp).toLocaleString()}
                  </Typography>
                </>
              }
            />
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => onRemoveWarning(warning.id)}
              sx={{ position: 'absolute', top: '8px', right: '8px', color: mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)' }}
            >
              <DeleteIcon />
            </IconButton>
          </WarningItem>
        ))}
      </AnimatePresence>
    </List>
  );
}

export default WarningsList;