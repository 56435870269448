// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  TextField, Button, Typography, Box, Paper, Dialog, DialogTitle, 
  DialogContent, DialogActions 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';

const StyledPaper = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  maxWidth: '400px',
  width: '100%',
}));

const Background = styled(motion.div)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  overflow: 'hidden',
});

const FullscreenBox = styled(Box)({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  boxSizing: 'border-box',
  overflowX: 'hidden',
});

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
});

const StyledButton = styled(Button)({
  color: 'white',
  borderColor: 'white',
  '&:hover': {
    borderColor: 'white',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
});

const FloatingParticle = ({ delay }) => (
  <motion.div
    style={{
      width: '5px',
      height: '5px',
      borderRadius: '50%',
      background: 'rgba(255, 255, 255, 0.5)',
      position: 'absolute',
    }}
    initial={{ y: '100vh', x: `${Math.random() * 100}vw` }}
    animate={{ y: -10, x: `${Math.random() * 100}vw` }}
    transition={{
      duration: 10 + Math.random() * 10,
      repeat: Infinity,
      delay,
      ease: 'linear',
    }}
  />
);

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
    backdropFilter: 'blur(10px)',
    borderRadius: theme.shape.borderRadius * 2,
  },
}));

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const success = await login(username, password);
      if (success) {
        navigate('/users');
      } else {
        setError('Invalid username or password. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <FullscreenBox>
      <Background
        animate={{
          background: [
            'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
            'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
          ],
        }}
        transition={{
          duration: 10,
          repeat: Infinity,
          repeatType: 'reverse',
        }}
      >
        {[...Array(20)].map((_, i) => (
          <FloatingParticle key={i} delay={i * 0.5} />
        ))}
      </Background>
      <StyledPaper 
        elevation={6}
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h4" component="h1" gutterBottom sx={{ color: 'white' }}>
            Welcome Back
          </Typography>
        </motion.div>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%', maxWidth: 400 }}>
          <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            <StyledTextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </motion.div>
          <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            <StyledTextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <StyledButton
              type="submit"
              fullWidth
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </StyledButton>
          </motion.div>
          <StyledButton
            fullWidth
            variant="text"
            onClick={handleOpenDialog}
            sx={{ color: 'white', textTransform: 'none' }}
          >
            No account?
          </StyledButton>
          {error && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <Typography color="error" align="center">
                {error}
              </Typography>
            </motion.div>
          )}
        </Box>
      </StyledPaper>
      <AnimatePresence>
        {openDialog && (
          <StyledDialog
            open={openDialog}
            onClose={handleCloseDialog}
            PaperComponent={motion.div}
            PaperProps={{
              initial: { y: -50, opacity: 0 },
              animate: { y: 0, opacity: 1 },
              exit: { y: -50, opacity: 0 },
              transition: { duration: 0.3 },
            }}
          >
            <DialogTitle>How to Register</DialogTitle>
            <DialogContent>
              <Typography>
                To register an account, please follow these steps:
              </Typography>
              <ol>
                <li>Go to the Discord server</li>
                <li>Run the /register command with your desired username and password</li>
                <li>Return here and log in</li>
              </ol>
              <Typography>
                If you need further assistance, please contact Peli for support.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </StyledDialog>
        )}
      </AnimatePresence>
    </FullscreenBox>
  );
}

export default Login