// src/config.js
const config = {
    guildId: '773807986430443532',
    defaultRoleId: '773809397000044564',  
    API_BASE_URL: 'https://pel.bot/api',
    clientId: '860440638348984320',
    welcomeChannelId: '810324539997028372', 
    quoteWebhookUrl: 'https://discord.com/api/webhooks/1160840590905319484/owEiysA11mFJBSBbE1PIgMSN3A6ZmpeXECojJXr2LI6qvtTlTcGyCjidqrWV_2f-_H2G',
    quoteEmoji: '📌',
    auditLogChannelId: '902822140758331433',
    modLogChannelId: '1038398063066218566',
    excludedChannels: ['CHANNEL_ID_1', 'CHANNEL_ID_2'], // from translation
    roles: {
        admin: '773809394349375488', 
        regular: '841644149652062249', 
    },
    modmail: {
        forumChannelId: '1267002846507962389',
        webhookUrl: 'https://discord.com/api/webhooks/1267024765475098686/xZhQoMDjx22lx8dD68aLhAvtSx7X8ZsLG6aT-b6YIY1c5aKhbE3rvJv8NL46ghcH5Jel'
    }
};

export const API_BASE_URL = config.API_BASE_URL;
export default config;