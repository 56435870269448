// src/components/Eula.js

import React from 'react';
import { Typography, Box, Paper, IconButton, Divider, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const StyledPaper = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(4),
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
  position: 'relative',
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

function Eula() {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box sx={{ padding: 4 }}>
      <StyledPaper
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <BackButton 
          onClick={() => navigate('/')} 
          color="primary"
          aria-label="back to homepage"
        >
          <ArrowBackIcon />
        </BackButton>
        <Typography variant="h4" gutterBottom sx={{ color: theme.palette.text.primary, mt: 4, mb: 3 }}>
          End User License Agreement
        </Typography>
        
        <Section>
          <Typography variant="h6" gutterBottom>1. Introduction</Typography>
          <Typography paragraph>
            This End User License Agreement ("Agreement") is a legal agreement between you ("User" or "You") and Pelicraft ("Pelicraft" or "We") concerning the use of Pelbot, a Discord bot and web dashboard, available at https://pel.bot ("Pelbot").
          </Typography>
          <Typography paragraph>
            By accessing, downloading, installing, or using Pelbot, you acknowledge that you have read, understand, and agree to be bound by the terms of this Agreement. If you do not agree to these terms, you must not use Pelbot.
          </Typography>
        </Section>

        <Divider />

        <Section>
          <Typography variant="h6" gutterBottom>2. Scope of Use</Typography>
          <Typography paragraph>
            Pelbot is a free service exclusively for users of Pelicraft-related enterprises. This includes users of Pelicraft's Minecraft and Discord server(s).
          </Typography>
        </Section>

        <Divider />

        <Section>
          <Typography variant="h6" gutterBottom>3. License Grant</Typography>
          <Typography paragraph>
            Subject to the terms and conditions of this Agreement, Pelicraft grants you a non-exclusive, non-transferable, limited license to use Pelbot solely for your personal, non-commercial use within the context of your Pelicraft-related enterprise. This license allows you to access and use Pelbot's functionalities, including but not limited to:
          </Typography>
          <List>
            <ListItem><ListItemText primary="User management" /></ListItem>
            <ListItem><ListItemText primary="Analytics" /></ListItem>
            <ListItem><ListItemText primary="Moderation" /></ListItem>
            <ListItem><ListItemText primary="Monitoring" /></ListItem>
            <ListItem><ListItemText primary="User statistics" /></ListItem>
            <ListItem><ListItemText primary="Long-term analytics" /></ListItem>
          </List>
        </Section>

        <Divider />

        <Section>
          <Typography variant="h6" gutterBottom>4. Data Collection and Use</Typography>
          <Typography paragraph>
            Pelicraft collects and processes various data related to your use of Pelbot, including, but not limited to:
          </Typography>
          <List>
            <ListItem><ListItemText primary="Discord messages: Including message content, message history, and statuses." /></ListItem>
            <ListItem><ListItemText primary="Voice activity: Information about voice chat activity and conversations." /></ListItem>
            <ListItem><ListItemText primary="Gameplay activity: Information about your gameplay activity, as reflected through Discord statuses or other sources." /></ListItem>
          </List>
          <Typography paragraph>
            Pelicraft uses this data for the following purposes:
          </Typography>
          <List>
            <ListItem><ListItemText primary="Providing and improving Pelbot's services: To enhance and develop Pelbot's functionalities for user management, analytics, and moderation." /></ListItem>
            <ListItem><ListItemText primary="Preventing fraud and abuse: To protect Pelbot from unauthorized use or misuse." /></ListItem>
            <ListItem><ListItemText primary="Maintaining security and stability: To ensure the smooth operation and security of Pelbot and its associated services." /></ListItem>
            <ListItem><ListItemText primary="Performing analytics and research: To gain insights into user behavior and improve the overall user experience." /></ListItem>
          </List>
        </Section>

        <Divider />

        <Section>
          <Typography variant="h6" gutterBottom>5. Data Privacy</Typography>
          <Typography paragraph>
            Pelicraft respects your privacy and is committed to protecting your data. We will handle your data in accordance with our Privacy Policy, which can be found at [insert link to Privacy Policy here]. By using Pelbot, you consent to the collection, use, and disclosure of your data as described in the Privacy Policy.
          </Typography>
        </Section>

        <Divider />

        <Section>
          <Typography variant="h6" gutterBottom>6. Disclaimer of Warranties</Typography>
          <Typography paragraph>
            Pelbot is provided "as is" and without any warranties of any kind, whether express or implied. Pelicraft makes no warranties, express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
          </Typography>
        </Section>

        <Divider />

        <Section>
          <Typography variant="h6" gutterBottom>7. Limitation of Liability</Typography>
          <Typography paragraph>
            To the fullest extent permitted by law, in no event shall Pelicraft or its affiliates be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of Pelbot, even if Pelicraft has been advised of the possibility of such damages. This limitation of liability includes, but is not limited to, any damages for loss of profits, data, or other intangible losses.
          </Typography>
        </Section>

        <Divider />

        <Section>
          <Typography variant="h6" gutterBottom>8. Indemnification</Typography>
          <Typography paragraph>
            You agree to indemnify and hold harmless Pelicraft and its affiliates from any and all claims, liabilities, damages, losses, and expenses (including attorneys' fees) arising out of or in connection with your use of Pelbot, your violation of this Agreement, or any claim that your use of Pelbot infringes on the intellectual property rights of a third party.
          </Typography>
        </Section>

        <Divider />

        <Section>
          <Typography variant="h6" gutterBottom>9. Termination</Typography>
          <Typography paragraph>
            This Agreement may be terminated by either party upon written notice to the other party. This Agreement may also be terminated immediately by Pelicraft if you breach any provision of this Agreement. Upon termination of this Agreement, all licenses granted under this Agreement shall immediately terminate.
          </Typography>
        </Section>

        <Divider />

        <Section>
          <Typography variant="h6" gutterBottom>10. Governing Law</Typography>
          <Typography paragraph>
            This Agreement shall be governed by and construed in accordance with the laws of the State of Victoria, Australia.
          </Typography>
        </Section>

        <Divider />

        <Section>
          <Typography variant="h6" gutterBottom>11. Entire Agreement</Typography>
          <Typography paragraph>
            This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior or contemporaneous communications, representations, or agreements, whether oral or written.
          </Typography>
        </Section>

        <Divider />

        <Section>
          <Typography variant="h6" gutterBottom>12. Severability</Typography>
          <Typography paragraph>
            If any provision of this Agreement is held to be invalid or unenforceable, such provision shall be struck from this Agreement and the remaining provisions shall remain in full force and effect.
          </Typography>
        </Section>

        <Divider />

        <Section>
          <Typography variant="h6" gutterBottom>13. No Waiver</Typography>
          <Typography paragraph>
            No waiver of any provision of this Agreement shall be effective unless in writing and signed by the waiving party.
          </Typography>
        </Section>

        <Divider />

        <Section>
          <Typography variant="h6" gutterBottom>14. Miscellaneous</Typography>
          <Typography paragraph>
            This Agreement may be amended from time to time by Pelicraft. Updated versions of this Agreement will be posted on the Pelbot website. You are responsible for reviewing any such updates. Continued use of Pelbot after any such updates constitutes your acceptance of the amended terms.
          </Typography>
        </Section>

        <Divider />

        <Section>
          <Typography variant="h6" gutterBottom>15. Contact Information</Typography>
          <Typography paragraph>
            If you have any questions about this Agreement, please contact us at legal@pelicraft.net.
          </Typography>
        </Section>
      </StyledPaper>
    </Box>
  );
}

export default Eula;
